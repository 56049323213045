<template>
<topbar></topbar>
  <div class="content">
    <iframe
      :src="viewerUrl"
      frameBorder="0"
      style="width: 100%; height: 500px"
    ></iframe>
    <div id="documentationWrapper" v-html="docs"></div>
  </div>
  <link rel="stylesheet" href="/path/to/styles/default.css" />
</template>
<script>
import docsMd from "raw-loader!../docs.md";
import marked from "marked";
import hljs from "highlight.js";
import Axios from "axios";
import Topbar from './TopBar.vue';

marked.setOptions({
  highlight: function (code) {
    return hljs.highlightAuto(code).value;
  },
});

export default {
  components: { Topbar },
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      docs: "",
      demoId: 94612816,
    };
  },
  mounted() {
    this.getDocs();
    window.downloadSampleJson = this.getSampleJson;
  },
  beforeUnmount(){
    delete window.downloadSampleJson; // Remove reference from window
  },
  computed: {
    viewerUrl: function () {
      return "/Viewer3D/demo-" + this.demoId;
    },
  },
  methods: {
    getDocs() {
      this.docs = marked(docsMd);
    },
    getSampleJson() {
      Axios.get("/Api/GetProduct/demo-" + this.demoId).then((res) => {
        var data = res.data;
        console.log(data);
        this.download("sample.json", JSON.stringify(data, null, 2))
      });
    },
    download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#documentationWrapper {
  background: #eeeeee;
  padding: 50px;
  border-radius: 9px;
}

#documentationWrapper pre {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 9px;
}
</style>

<style scoped>
@import "../../node_modules/highlight.js/styles/github.css";
</style>

<style scoped>
.content {
  text-align: left;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding-top:64px;
}
</style>